import cookies from './en/cookies';
import privacy from './en/privacy';
import terms from './en/terms';

export default {
  common: {
    backLabel: 'Go back',
    confirmPasswordLabel: 'Confirm password',
    confirmPasswordValidationMessage: 'Please ensure that your passwords match.',
    emailLabel: 'Email',
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    messageLabel: 'Message',
    organizationLabel: 'Organization',
    organizationRoleLabel: 'Role',
    passwordLabel: 'Password',
    passwordValidationMessage: 'Please provide a password that is six (6) characters long, with at least one uppercase letter, one lowercase letter, and one number.',
    serverErrorContent: 'Sorry, but we’ve encountered an error while processing this form. Please try again later.',
    serverErrorMessage: 'Something went wrong...',
    serverSuccessContent: 'We will be in touch shortly.',
    serverSuccessMessage: 'Thank you!',
    validationMessage: 'Please correct the following issues:',
  },
  faq: {
    contactFormContent: 'We’re ready to lead you into the future. Create virtual environments with us',
    contactFormHeading: 'Still have questions?',
    heading: {
      subTitle: 'FAQ',
      title: 'Frequently asked questions:',
    },
    questions: [
      {
        answer: {
          text: 'The free trial version lasts for 30 days. It includes the core feature set, so you can experience the start of your own course creation.',
        },
        question: 'How long does the free trial last?',
      },
      {
        answer: {
          text: 'You can easily create a simple course using still images.',
          videos: ['5ooeHWUe_74'],
        },
        question: 'Where do I start to create my first course?',
      },
      {
        answer: {
          text: 'Choose the 360° course option for a virtual-course look.',
          videos: ['YurEBHW7TDg', '16yLTi8-mK0'],
        },
        question: 'How can I create an interactive visual course?',
      },
      {
        answer: {
          text: 'TAGGIS is the ideal tool to create a virtual environment step-by-step, with predefined actions through the use of interactive checklists.',
          videos: ['OvqFofWo63g'],
        },
        question: 'How can I create an interactive environment where users can learn about specific procedures?',
      },
      {
        answer: {
          text: 'The upgraded version gives you more choice. This includes: “Final Exam” scorm export, copy existing courses, create step-by-step procedures and presentations, add your own branding, and more.',
        },
        question: 'What does the full package include?',
      },
      {
        answer: {
          text: 'You can stitch images using the [Marzipano](https://www.marzipano.net/tool/) tool.',
          videos: ['MFho5HhrguQ'],
        },
        question: 'How can I stitch my 360 images for further use on the TAGGIS platform?',
      },

      {
        answer: { text: 'You can add:\n * Images in .png and .jpg, formats;\n * Video in .mov, .mpeg4, .avi, .wmv, .mp4;\n * Audio in .waw and record audio directly from your device.' },
        question: 'What media formats are available?',
      },
      {
        answer: { text: 'We recommend uploading video files that are less than 250MB.' },
        question: 'What file sizes can I upload onto the TAGGIS platform?',
      },
      {
        answer: { text: 'You can do this in the "Create quiz" tab which can be found in the top-right corner of every hotspot. You can:\n * Add images to every question\n * Use a countdown timer\n * Set as many answers as you want.' },
        question: 'How can I compose a Final Exam?',
      },
      {
        answer: { text: 'No, you can add as many images as you want.' },
        question: 'Is there a limit of images I can upload to my account?',
      },
      {
        answer: { text: 'Yes, branding is available within the full version.' },
        question: 'Can I set up my own custom logo and branding?',
      },
      {
        answer: {
          text: 'Yes, you can distribute them via your LMS, which supports SCORM 1.2 or SCORM 2004.',
        },
        question: 'Can I distribute my TAGGIS courses via my own LMS?',
      },
      {
        answer: { text: 'Yes, they are supported on mobile devices and tablets.' },
        question: 'Can TAGGIS eLearning courses be run and experienced on mobile devices?',
      },
      {
        answer: { text: 'All your courses are securely stored on your TAGGIS account. You can view them, edit them and download them again at any time.' },
        question: 'My download link has expired, how can I get my zip-file?',
      },
      {
        answer: { text: 'Yes you can use a mix of image styles. We recommend that you use the "Presentations" feature to stitch together still and 360 images.' },
        question: 'Can I use both 360 images and regular still images to create courses and presentations with TAGGIS?',
      },
      {
        answer: { text: 'The process is simple and effective:\n * Export your TAGGIS-created course via SCORM packaging and host it in the LMS system of your choice - all TAGGIS modules are SCORM compliant\n * Copy your course as a unique URL link and easily share with your end users and colleagues\n * Embed your course on your website to make it accessible to others \n * All content is hosted on our AWS Amazon servers \n * The TAGGIS platform is a cloud-based service, so production and course updates take place in real time.' },
        question: 'How do I export and share my courses?',
      },
    ],
  },
  newsPage: {
    heading: {
      subTitle: 'News',
      title: 'What is new?',
    }
  },
  landingPage: {
    allTools: {
      heading: "All your tools in one space",
      content: ['\uFFED One system – for Flight Ops & Training\n\n \uFFED Modular products – grow as needed \n\n \uFFED We innovate & create more tools'],
      subheading: "Quality. Reliability.",
    },
    aviationVideoView: {
      heading: 'DocBrowser - SaaS since 2013',
      content: 'Digital. Automatic. Modular.',
      videoId: 'rfzu8teAtGY',
    },
    aviationEdocs: {
      heading: 'Aviation eDocs',
      content: 'Our services in focus',
    },
    blueHeadingText: 'It’s easy. We are your one stop shop for e-docs',
    downloadBrochure: {
      heading:'Want to learn more?',
      buttonLabel: 'Download full company brochure',
      fileName: 'DocBrowser - Product Brochure 2023.pdf',
    },
    contactUs: {
      buttonLabel: 'Accept & send',
      termsContent: 'By submitting this form, you hereby agree that Virtual eTraining Software may collect, obtain, store and process your personal data to handle your request. We respect your [privacy](/privacy).',
    },
    coronaInfo: {
      buttonLabel: 'COVID-19',
      content: 'We have something for you during this challenging time: \n Sign-up with DocBrowser & you get TAGGIS, a standalone Virtual Training platform, totally for free. Yes, it is as good as it sounds!',
      heading: 'Awesome that you are here!',
      signUpLink: '/book-meeting',
      signUpText: 'Free Trial DocBrowser',
      taggisLink: 'https://www.taggis.com/',
      taggisText: 'About TAGGIS',
    },

    didYouKnow: {
      heading: 'Did you know?',
      subHeading: 'You can now host, distribute and track all SCORM-compatible e-learning courses through DocBrowser, plus any virtual training created in TAGGIS. Learning has never been so simple.'
    },

    howOurPricingWorks:{
      heading: 'How our pricing works',
      subHeading: "Try us. You'll see that our products are the cheapest on the market.",
      buttonLabel: 'Book a meeting',
      href: '/book-meeting',
      columns: [
        {
          listHeader: 'Onboarding',
          listItems: [
            {title: 'Standardized onboarding process'},
            {title: 'No license fee during onboarding', content:['No licenses are issued during the onboarding period to eliminate the costly & time consuming exercise of changing suppliers and products/services']},
            {title: 'We take care of switching your suppliers'},
          ]
        },
        {
          listHeader: 'Subscription fees',
          listItems: [
            {title: 'Per user pricing', content: ['The more users you have the less you pay per user']},
            {title: 'Per product pricing', content:['The more products you use the less you pay', 'Products are modular so you can start small and grow bigger, but also downgrade according to the company situation']},
            {title: 'No cost per manual', content: ['Сreate and distribute as many manuals as you want']},
          ]
        },
        {
          listHeader: 'Our promises',
          listItems: [
            {title: 'Open systems', content: ['You can always get your data out - it belongs to you']},
            {title: 'Fair cost structure', content:['Pay as you go', 'No hidden costs']},
            {title: 'Service & support', content: ['Competent and efficient service and support']},
            {title: 'No strings attached', content: ['Use our systems as long as you want with the option of monthly termination']},
          ]
        }
      ]
    },
    ourPricing: {
      heading: "Our pricing",
      content: "Our unique offering comes at uniquely low prices.",
      contentEnd: "Start saving today!",
    },
    productPortfolio:{
      heading: 'Product Portfolio',
      href: '/solutions',
      buttonLabel: 'Find out more',
      subHeaderContent: ['Our expanding portfolio makes sure that you have everything you need for', 'your digital content - in one place.  Each product works individually and together.', 'Grow to suit your business.'],
      downloadApps: [
        {
          image: {
            src: 'iDocBrowser_portfolio_image.jpg',
            alt: 'iDocBrowser',
          },
          title: 'iDocBrowser',
          content: 'iOS App automatically provides offline content and updates. Its strong end-user focus allows easy navigation, quick searches and content to be filtered.',
          href: 'https://apps.apple.com/app/idocbrowser/id416827407',
        },
        {
          image: {
            src: 'iFlyThere_portfolio_image.jpg',
            alt: 'iFlyThere',
          },
          title: 'iFlyThere',
          content: 'A location aware guide for flight crew and ground staff. It holds all destination-related information in a fully dynamic central database.',
          href: 'https://apps.apple.com/app/iflythere/id515843089',
        },
        {
          image: {
            src: 'iReform_portfolio_image.jpg',
            alt: 'iReForm',
          },
          title: 'iReForm',
          content: 'A creator and user-friendly tool to collect information easily through digital forms/reports.',
          href: 'https://apps.apple.com/app/ireform/id842441054',
        },
        {
          image: {
            src: 'Flight_Briefing_portfolio_image.jpg',
            alt: 'Flight_Briefing',
          },
          title: 'FlightBriefing',
          content: 'A complete replacement of the traditional paper briefing package for EFB use. Compatible with all major flight briefing systems.',
          href: 'https://apps.apple.com/app/flight-briefing/id931272065',
          label: 'new',
        },
        {
          image: {
            src: 'Taggis_portfolio_laptop.png',
            alt: 'TAGGIS',
          },
          title: 'TAGGIS',
          content: 'Create virtual content for training. The world’s largest aviation training organisation, CAE Inc., works with TAGGIS to lead the way for immersive aviation training.',
          link: 'https://taggis.com',
        },
      ],
    },
    productSuite: {
      content: "Digital. Automatic. Modular.\n Check out our video to learn more",
      heading: "SaaS since 2013",
      videoId: "hDkwne9Y7Yk",
      image: {
        src: 'product-suite.jpg',
        alt: 'Product Suite',
      },
    },
    revisionCycle: {
      content: "Our video talks you through the steps",
      heading: "Keep your compliance needs in check.",
      videoId: "tA7qvMsyGzQ",
      image: {
        src: 'the-content-revision-cycle.jpg',
        alt: 'Revision Cycle',
      },
    },
    footer: {
      address: 'Virtual eTraining Software AB\nTavastgatan 26B\n118 24 Stockholm\nSweden\n[info@docbrowser.io](mailto:info@docbrowser.io)',
      content: 'Copyright © {year} Virtual eTraining Software AB. All rights reserved.',
    },
    getInTouch: {
      content: 'Digitize and Visualize Your Documents. We’re ready to lead you into the future.',
      heading: 'Get in touch with us',
    },
    hero: {
      slides:[
        {heading: 'Check out our newest product', subHeading: 'The FlightBriefing, digital app for EFB'},
        {heading: 'Cut your costs', subHeading: 'with the DocBrowser suite'},
        {heading: 'We know about aviation', subHeading: 'with pilots on board'},
        {heading: 'Content needs are met', subHeading: 'with product perfection'},
        {heading: 'Choose your modules', subHeading: 'for the best value for money'},
        {heading: ' Affordable products', subHeading: 'grow with your business'},
        {heading: ' Users act better', subHeading: 'with timely & correct information'},
        {heading: ' Automatic ', subHeading: 'online/offline info & updates'},
        {heading: '', subHeading: 'Your data belongs to you - ALWAYS'},
      ]
    },
    new: {
      buttonLabel: 'Stay tuned',
      content: ['You can now host, distribute and track all SCORM-compatible e-learning courses through DocBrowser, plus any virtual training created in TAGGIS. Simply upload your courses and send them to the end-users, flight crew/ground staff, who will be able to complete their compulsory training quickly in a totally secure manner.','Learning has never been so simple.'],
      heading: 'The integrated LMS is on the way',
      href: '/sign-in',
    },
    ourPartners: {
      heading: 'Our partners & Us',
      content: 'Our product suite is produced through effective collaboration. Together with other experts in the digital and virtual worlds, we deliver a fast, safe, seamless and automated content service.',
      images: [
        { src: 'doc_browser_gray.svg', alt: 'DocBrowser' },
        //TODO: add flight briefing, maybe not needed as we will remove it
        { src: 'cae.svg', alt: 'CAE' },
        { src: 'virtual_e_training_gray.svg', alt: 'Virtual eTraining' },
      ],
      buttonLabel: 'Find out more',
      href: '/#contact',
    },
    ourGrowingFleet: {
      heading: 'Join our growing fleet',
      images: [
        { src: 'tui.svg', alt: 'TUI' },
        { src: 'sas.svg', alt: 'SAS' },
        { src: 'novair.svg', alt: 'NOVAIR' },
        { src: 'condor.svg', alt: 'CONDOR' },
        { src: 'ryanair.svg', alt: 'RYANAIR' },
        { src: 'norwegian.svg', alt: 'NORWEGIAN' },
      ],
    },
    navigation: {
      items: [
        { href: '/partners', name: 'About & Partners' },
        { href: '/solutions', name: 'Solutions' },
        { href: '/pricing', name: 'Pricing', label: "TRY IT" },
        { href: '/#free-trial', name: 'Free trial' },
        // { href: '#camera', name: 'Camera' },
        { href: '', name: 'Resources', subItems: [
          { href: '/news', name: 'News' },
          // { href: '/video-casts', name: 'Video Casts' },
          { href: '/social-media', name: 'Social Media' },
          { href: '/brochure', name: 'Brochure' },
        ] },
        { href: '/#contact', name: 'Contact' },
        // { href: '/faq', name: 'FAQ' },
        { href: '/sign-in', name: 'Sign in' },
        { href: '/book-meeting', name: 'Book Meeting', displayAsButton: true },
      ],
    },
    whatWeDo: {
      content: 'We bring you a unique digital content and distribution system, made by aviation professionals for aviation professionals.',
      heading: 'We invent digital tools for the airline industry',
      subheading: 'WHAT WE DO',
    },
    wingDoxLearnMore:{
      buttonLabel: 'Learn more about WingDox',
      href: 'https://www.wingdox.com/',
    },
    whoWeAre: {
      buttonLabel: 'Sign up',
      content: 'DocBrowser is a partner of [gds](https://www.gds.eu/en/content-management-solutions), a technical documentation expert, and a subsidiary of [Virtual eTraining Software](http://www.virtualetraining.com/), which has helped to shape the digital landscape of aviation training. The adaptability of DocBrowser makes it very useful for any digital service and its end users. A second subsidiary, [TAGGIS](https://www.taggis.com/), enables companies to produce their own interactive visual environments. Soon these two sister companies will integrate to produce a totally new generation of digital e-docs.',
      heading: 'A new generation: visualization',
      href: '/book-meeting',
      subheading: 'Who we are',
    },
    freeTrial: {
      buttonLabel: 'Free trial',
      content: ['Airlines work to tight margins. Reducing costs without compromising on quality, safety and reliability is key. ', '__<span style="color: black">Try the *DocBrowser suite for free. 30 days, no credit card, no obligation</span>__'],
      heading: 'Cut your costs',
      href: '/book-meeting',
      underButtonText: '*Freemium is for iOS apps & includes: iDocBrowser, iFlyThere and TAGGIS'
    },
    quotes: {
      heading: 'Let’s hear it from our clients',
      quotes: [
        {
          author: 'Barry van der Waart, Airline Application Manager, TUI',
          quote: 'In TUI fly we use iDocBrowser to manage documents and manuals on our iPad portable EFBs and cabin C-PEDs. Also the webclient allows staff members of the operations department to consult the necessary documents on any other device to support the crew. The admin portal contains some powerful features to categorise and assign documents filtered by profile or fleet or to track required-to-read OPS notices. The ability to organise all of this in connection with our MDM system makes this the ideal tool for our airline.',
          logo: { src: 'tui_white.svg' , alt: 'TUI' }
        },
        {
          author: 'Benedikt Diegler, Head of Flight Operations Administration, Condor Flugdienst GmbH',
          quote: 'At Condor we were looking for an easy accessible solution to distribute our operational documentation and destination information on the Electronic Flight Bags. iDocBrowser and iFlyThere have been selected as they are providing necessary and helpful functions within an user friendly environment. This is valid for the pilots as the main users as well as for our backoffice staff. The feedback we`ve received is overwhelming and the overall stability of the applications is very satisfying.',
          logo: { src: 'condor_white.svg' , alt: 'Condor' }
        },
        {
          author: 'Capt Johan Boström, MsC, Director Flight Operations, Novair',
          quote: 'Using DocBrowser was a revolutionary upgrade for Novair. It continues to be an important tool for us to reach out to our staff. DocBrowser is a beautifully-functioning system when it comes to receiving and reading company information, key operational documentation, training material, and much more that an aviation employee needs in their job.The fast and easy distribution with the ‘read and receipt’ function has greatly facilitated the administrative work. In addition, the interface is neat, clear and logical for the user.',
          logo: { src: 'novair_white.svg' , alt: 'NOVAIR' }
        },
        {
          author: 'Jim Cairns, VP Maintenance Training, CAE-Pelesys',
          quote: 'Adding the TAGGIS product to our courseware provides a sense of realism that can virtually replace the need of going out to the aircraft.',
          logo: { src: 'cae_white.svg' , alt: 'CAE' }
        },
        {
          author: 'Siri Aronsen, Ground Instructor, Training Department, Norwegian Air Shuttle',
          quote: 'The TAGGIS editing interface is simple and user friendly. We can add text and images to the 360° environment, together with tests and other updates, which saves us both time and money.',
          logo: { src: 'norwegian_white.svg' , alt: 'NORWEGIAN' }
        },
      ],
    },
  },

  solutionsPage:{
    //TODO: add flightBriefing
    ireformApp: {
      buttonLabel: 'Free trial',
      content: ['Simply create, distribute and collate - any form/user report\n\n', '\uFFED Gather employee feedback/requests efficiently \n\n \uFFED Intuitive design for creator & graphical user interface \n\n \uFFED Forward submitted forms and reports to relevant systems, or employee groups in various usable formats'],
      heading: 'iReForm',
      href: '/book-meeting',
      videoId: 'uFLTOjrAC-Q',
      image: {
        src: 'iReForm.png',
        alt: 'Revision Cycle',
      },
    },
    docBrowserWeb: {
      content: `* Digitize and distribute your content - quickly \n\n* \uFFED iDocBrowser sends, tracks, collects - and interacts \n\n* \uFFED Send information instantly \n\n* \uFFED Strong end-user focus:\n\n* > \uFF0D easy navigation\n\n* > \uFF0D quick searches\n\n* > \uFF0D content to be filtered\n\n* \uFFED Bonus tools included`,
      heading: 'iDocBrowser',
      imageToRight: true,
      buttonLabel: 'Try free trial',
      href: '/book-meeting',
      target: '_self'
    },
    iFlyThereVideoView: {
      heading: 'iFlyThere - Your Interactive Destination Guide',
      content: 'When you are going somewhere get iFlyThere. ',
      subContent: 'It’s unique, it’s global, it’s customizable. There is something for everyone.',
      videoId: '065fN1FarJw',
    },
    IReFormVideoView: {
      heading: 'iReForm',
      content: 'Simply create and distribute forms to collect information and feedback from your employees',
      videoId: 'uFLTOjrAC-Q',
      image: {
        src: 'iReForm.png',
        alt: 'Revision Cycle',
      },
    },
    FlightBriefing: {
      heading: 'FlightBriefing',
      content: `* Replace your static, paper-based flight briefing packages with dynamic, electronic flight briefing \n\n* \uFFED 
      *Data Integration*: Import information from major flight planning systems like PPS, NavBlue, Jeppesen, Lido, etc.\n\n* \uFFED 
      *Dynamic Calculations*: Recalculate fuel and flight times before take-off and in-flight at waypoints.\n\n* \uFFED 
      *Weather Information*: Updated TAF and Metar in your briefing package.\n\n* \uFFED 
      *Charting and Reporting Links*: Connect with charting apps (NavBlue, Jeppesen, Lido) and reporting apps (IQSMS).\n\n* \uFFED
      *System Integration*: Compatible with third-party systems, e.g Leon, PDC.\n\n* \uFFED
      *Centralized Information*: Consolidate Notams, weather, and other crucial data in one place.\n\n* \uFFED
      *Information Filtering*: Emphasize critical information and hide what’s not needed.\n\n*`,
      buttonLabel: 'Download Brochure',
      href: 'FlightBriefing Promo 2024.pdf',
      target: '_blank',
      download: true
    }, 
    Taggis: {
      content: "* Create your own virtual training environments for distribution via any LMS or iDocBrowser. Whatever you choose, TAGGIS is easy to use.\n\n* Perfect for:\n\n* \uFFED Reducing aircraft training sessions with cockpit and cabin familiarization \n\n* \uFFED Provide your crew with authentic aircraft training experience \n\n* \uFFED Always accessible on a mobile device",
      heading: 'TAGGIS',
      imageToRight: false,
      buttonLabel: 'Visit partner website',
      href: 'https://taggis.com/',
      target: '_blank'
    },
  },

  pricingPage: {
    hero: {
      heading: "PRICING",
      content: "Our business model is efficient. Through our various partnerships we are able to offer the highest quality with reliable products that come at the lowest prices.\n Work it out now. You’ll see that our products are the most competitive on the market.",
      buttonLabel: 'Book a demo',
      href: '/book-meeting',
    },
    otherProducts: {
      heading: "Other products in the DocBrowser portfolio",
      rows: [
        //TODO: add flightBriefing page here
        ["TAGGIS", "Create virtual training courseware for distribution to crew via iDocBrowser or optional LMS (Learning Management System).", "See pricing on [TAGGIS](https://taggis.com/pricing)"],
      ]
    },
    info: {
      heading: "More detailed info",
      rows: [
        ["Onboarding", "All apps of the DocBrowser Suite are ready to use. We offer onboarding services to get you started even faster - please inquire."],
        ["Hosting", "Hosted on AWS in Frankfurt, Germany with daily backups being performed on a regular basis."],
        ["Service Levels (reaction times)*:", {
          rows: [
            ["Service level", "1", "2", "3"],
            ["Critical", "Next workday", "Morning: same workday Afternoon: next workday", "Morning: same workday Afternoon: next workday"],
            ["Major", "Next workday + 5", "Next workday", "Morning: same workday Afternoon: next workday"],
            ["Minor", "Next workday + 5", "Next workday + 4", "Next workday + 3"],
          ],
          afterText: "* For more details please request our Service Level Agreement, our Risk Register, and our HMI assessment proposal",
        }],
        ["Initial Term", "1 year"],
        ["Payment Method", "Invoice"],
        ["Payment Term", "Quarterly"],
        ["Termination of contract", "We offer a monthly termination option"],
        ["Try before you buy", "Free trial"],
        ["No cost per manual", "Unlimited creation & distribution"],
      ]
    },
    pricingTable: {
      heading: "Calculator for DocBrowser products",
      subHeading: "Pricing for DocBrowser Products is based on number of users per app. <br /> There is a minimum licence fee of € 200,00/month.",
      hint: "Type here the number of users",
      supportHint: "<b>Support Level 1</b><br /><b>Reaction times:</b> critical: next workday major: next workday +1 minor: next workday +5 <br /><br /> <b>Support Level 2</b><br /> <b>Reaction times:</b> critical: morning - same workday afternoon - next workday major: next workday minor: next workday +4 <br /><br /> <b>Support Level 3</b><br /> <b>Reaction times:</b> critical: morning - same workday afternoon - next workday major: morning - same workday afternoon - next workday minor: next workday +3",
      table: {
        head: {
          product: "Product",
          users: "App Users",
          basePrice: "Base Price",
          discount: "Discount",
          discountedPrice: "Discounted Price"
        },
        subHead: [
          "DocBrowser Suite (Content Delivery)",
          "",
          "Per month",
          "Per year",
          "",
          "Per month",
          "Per year",
          "Per app user/month",
        ],
        rows: {
          iDocBrowser: "iDocBrowser",
          iFlyThere: "iFlyThere",
          iReForm: "iReForm",
          Subtotal: "Subtotal",
          support: "Support+SL",
          sum: "Total",
        }
      }
    },
    fbPricing: {
      fbPricingTable: {
        heading: "Calculator for FlightBriefing",
        subHeading: "Pricing for FlightBriefing is based on number and type of aircraft. <br> There is a minimum fee of € 1.400,00/month.",
        hintStd: "Enter the number of aircrafts with a weight of less than 100 tons.",
        hintWb: "Enter the number of aircrafts with a weight of more than 100 tons.",
        supportHint: "<b>Support Level 1</b><br /><b>Reaction times:</b> critical: next workday major: next workday +1 minor: next workday +5 <br /><br /> <b>Support Level 2</b><br /> <b>Reaction times:</b> critical: morning - same workday afternoon - next workday major: next workday minor: next workday +4 <br /><br /> <b>Support Level 3</b><br /> <b>Reaction times:</b> critical: morning - same workday afternoon - next workday major: morning - same workday afternoon - next workday minor: next workday +3",
        table: {
          head: {
            product: "Product",
            ac: "Aircrafts",
            price: "Price"
          },
          subHead: [
            "Digital FlightBriefing",
            "",
            "Per month",
            "Per year",
            "Per aircraft/month",
            "Per aircraft/year",
          ],
          rows: {
            FlightBriefing: "FlightBriefing",
            licenses: 'Licenses and hosting',
            support: "Support+SL",
            sum: "Total",
          }
        }
      }
    },
  },

  aboutAndPartnersPage: {
    buttonLabel: 'Contact us to become a collaborator.',
    headerTitle: 'About & Partners',
    headerContent: 'We have been providing software as a service (SaaS) that is fully focused on digital content since 2013. We serve the safety-critical aviation industry because compliance is huge, and with it comes volumes of information to get through.',
    content: ['Our strategy focuses on expert and complementary collaboration with other digital industry leaders. Together, we bring an exceptional product suite. We sort out compliance and make sure the information gets to everyone. Plus we throw in other digital apps so that flight crew and flight ops team can focus on doing their job.'],
    brandImages: [{ src: 'doc_browser_gray.svg', alt: 'DocBrowser' },
    //TODO: add flight briefing 
    { src: 'cae.svg', alt: 'CAE' },
    { src: 'virtual_e_training_gray.svg', alt: 'Virtual eTraining' },],
    href: '/#contact',
  },

  bookMeetingPage: {
    heading: 'Book Your Meeting',
    subHeading: 'Book a 15-minute meeting slot to tell us what you want to know about.',
    bookOptionsHeading: 'Select the applicable option(s) below so we can prepare:',
    emailLabel: 'Enter your email',
    bookingOptions: ['Digital content distribution (online/offline)', 'Managing and distributing destination information', 'Authoring and distributing digital forms', 'Creating and deploying virtual training', 'Managing digital flight briefing'],
    buttonLabel: 'Book',
    href: '/#contact',
  },

  socialMediaPage: {
    heading: 'Follow DocBrowser on social media',
    linkedInLogo:'linkedInLogo.png',
    videos: ['065fN1FarJw', 'rfzu8teAtGY', 'YurEBHW7TDg', '16yLTi8-mK0', 'OvqFofWo63g']
  },

  notifications: {
    cookiePolicy: {
      buttonLabel: 'Accept cookies',
      content: '**We use [cookies](/cookies) to personalise content and ads, and to analyse our traffic.** We also share information about your use of our site with our social media, advertising, and analytic platforms who may combine it with other information that you’ve provided to them.',
    },
    pwaUpdate: {
      buttonLabel: 'Refresh now',
      content: '**There are new updates available.** We’ve made some changes to this page. Refresh and see the latest version.',
    },
  },
  terms: {
    cookies,
    privacy,
    terms,
  },
};
